#root, .App {
    display: flex;
    flex-flow: column;
    min-height: 100vh;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    flex: 0 0 auto;
}

.content {
    background-color: var(--surface-0);
    display: flex;
    flex-flow: column;
    flex: 1 1 auto;
}

.content > .container,
.content > .container-fluid {
    width: 100%;
    padding: 2rem 1rem;
}

@media screen and (min-width: 1400px){
    .content > .container {
        width: 100%;
        max-width: 1320px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;
        padding-right: 0;
    }
}

.login-wrapper {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.App-footer {
    flex: 0 0 auto;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.p-menubar .p-submenu-list {
    z-index: 900;
}

.p-multiselect-label:has(> .p-chip) {
    display: flex;
    flex-flow: row wrap;
    gap: 0.3rem;
}

.p-multiselect-label .p-chip {
    margin: 0;
    padding: 0.3rem 0.7rem;
}

.app-dialog {
    max-width: 800px;
    width: 90%;
}

.app-dialog-full {
    width: 98%;
}

.app-confirmation-dialog {
    max-width: 600px;
    width: 90%;
}

.app-registration-dialog {
    max-width: 460px;
    width: 90%;
}

.app-registration-dialog .p-password-input {
    width: 100%;
}

.app-user-properties-dialog {
    max-width: 1200px;
    width: 90%;
}

.username-wrapper {
    display: flex;
    flex-flow: row nowrap;
    gap: 0.1rem;
    justify-content: flex-start;
    align-items: center;
    margin-left: 1rem;
}

.user-menu .p-button-label {
    text-align: start;
}

.note {
    color: #9A9A9A;
    font-size: 0.8rem;
}

.dict-items {
    display: flex;
    flex-flow: row wrap;
    gap: 0.25rem;
}

.dict-item {
    background-color: rgba(0, 0, 0, 0.075);
    color: rgba(0, 0, 0, 0.87);
    border-radius: 16px;
    padding: 0.25rem 0.55rem;
}

.p-calendar-w-btn .p-datepicker-trigger.p-button {
    background-color: var(--primary-color);
}


.modified,
.modified .old {
    display: block
}

.modified {
    font-weight: bold;
    color: blue;
}

.modified .old {
    color: lightgray;
    text-decoration: line-through;
}

.created {
    font-weight: bold;
    color: green;
}