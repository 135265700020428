body {
    height: 100%;
    min-height: 100%;
    margin: 0;
    background-color: var(--surface-ground);
    font-family: var(--font-family);
    font-weight: normal;
    color: var(--text-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 576px) {
    body {
        padding: 0;
    }
}

a {
    color: var(--primary-color);
}

h2 {
    margin-top: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#logo {
    height: 24px;
    width: auto;
}

#profile-menu ul,
#profile-menu li {
    margin: 0 !important;
    padding: 0 !important;
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
    margin-inline-start: 0 !important;
    margin-inline-end: 0 !important;
}

#profile-menu li {
    list-style-type: none;
}

#user-documents.p-dataview-grid .doc-type {
    height: 5rem !important;
    width: auto !important;
}

#user-documents.p-dataview-list .doc-type {
    height: 3rem !important;
    width: auto !important;
}

.card {
    background: var(--surface-card);
    padding: 2rem !important;
    margin-bottom: 1rem;
}

.card .field {
    display: flex;
    flex-flow: column;
}

.card .field .label {
    font-weight: bold;
    color: var(--primary-color);
}

.card .field input {
    width: 100%;
}

.card .field .p-password {
    width: 100%;
}

.attachments {
    overflow: hidden;
}

.p-menubar {
    border: none;
}


.opacity-transition-enter,
.opacity-transition-appear {
    opacity: 0;
}

.opacity-transition-enter-active,
.opacity-transition-appear-active {
    opacity: 1;
    transition: opacity 300ms;
}

.opacity-transition-exit {
    opacity: 1;
}

.opacity-transition-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}